import { BrowserRouter, Route, Switch } from "react-router-dom"
import AddVolunteerPage from "./pages/AddVolunteerPage"
import DisplayHelpsPage from "./pages/DisplayHelpsPage"
import DisplayUsersPage from "./pages/DisplayUsersPage"
import DisplayVolunteerPage from "./pages/DisplayVolunteerPage"
import HomePage from "./pages/Home"
import './App.css'
import LoginPage from "./pages/LoginPage"
import AddTalukaPage from "./pages/AddTalukaPage"
import ShowTalukaPage from "./pages/ShowTalukaPage"

const App = () => {
    return(
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route path="/login" component={LoginPage} />
                <Route path="/helps" component={DisplayHelpsPage}/>
                <Route path="/add-volunteer" component={AddVolunteerPage}/>
                <Route path="/add-taluka" component={AddTalukaPage}/>
                <Route path="/display-volunteer" component={DisplayVolunteerPage}/>
                <Route path="/display-users" component={DisplayUsersPage}/>
                <Route path="/show-talukas" component={ShowTalukaPage}/>
            </Switch>
        </BrowserRouter>
    )
}

export default App