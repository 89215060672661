import React, { useEffect, useState } from 'react'
import Loader from 'react-loader-spinner'
import Navbar from '../components/Navbar'

let stateObject = {
    "Andhra Pradesh": [
        "Adilabad",
        "Anantapur",
        "Chittoor",
        "Kakinada",
        "Guntur",
        "Hyderabad",
        "Karimnagar",
        "Khammam",
        "Krishna",
        "Kurnool",
        "Mahbubnagar",
        "Medak",
        "Nalgonda",
        "Nizamabad",
        "Ongole",
        "Hyderabad",
        "Srikakulam",
        "Nellore",
        "Visakhapatnam",
        "Vizianagaram",
        "Warangal",
        "Eluru",
        "Kadapa",
        "other"
    ],
    "Arunachal Pradesh": [
        "Anjaw",
        "Changlang",
        "East Siang",
        "Kurung Kumey",
        "Lohit",
        "Lower Dibang Valley",
        "Lower Subansiri",
        "Papum Pare",
        "Tawang",
        "Tirap",
        "Dibang Valley",
        "Upper Siang",
        "Upper Subansiri",
        "West Kameng",
        "West Siang",
        "other"
    ],
    "Assam": [
        "Baksa",
        "Barpeta",
        "Bongaigaon",
        "Cachar",
        "Chirang",
        "Darrang",
        "Dhemaji",
        "Dima Hasao",
        "Dhubri",
        "Dibrugarh",
        "Goalpara",
        "Golaghat",
        "Hailakandi",
        "Jorhat",
        "Kamrup",
        "Kamrup Metropolitan",
        "Karbi Anglong",
        "Karimganj",
        "Kokrajhar",
        "Lakhimpur",
        "Marigaon",
        "Nagaon",
        "Nalbari",
        "Sibsagar",
        "Sonitpur",
        "Tinsukia",
        "Udalguri",
        "other"
    ],
    "Bihar": [
        "Araria",
        "Arwal",
        "Aurangabad",
        "Banka",
        "Begusarai",
        "Bhagalpur",
        "Bhojpur",
        "Buxar",
        "Darbhanga",
        "East Champaran",
        "Gaya",
        "Gopalganj",
        "Jamui",
        "Jehanabad",
        "Kaimur",
        "Katihar",
        "Khagaria",
        "Kishanganj",
        "Lakhisarai",
        "Madhepura",
        "Madhubani",
        "Munger",
        "Muzaffarpur",
        "Nalanda",
        "Nawada",
        "Patna",
        "Purnia",
        "Rohtas",
        "Saharsa",
        "Samastipur",
        "Saran",
        "Sheikhpura",
        "Sheohar",
        "Sitamarhi",
        "Siwan",
        "Supaul",
        "Vaishali",
        "West Champaran",
        "Chandigarh","other"
    ],
    "Chhattisgarh": [
        "Bastar",
        "Bijapur",
        "Bilaspur",
        "Dantewada",
        "Dhamtari",
        "Durg",
        "Jashpur",
        "Janjgir-Champa",
        "Korba",
        "Koriya",
        "Kanker",
        "Kabirdham (Kawardha)",
        "Mahasamund",
        "Narayanpur",
        "Raigarh",
        "Rajnandgaon",
        "Raipur",
        "Surguja","other"       
    ],
    "Dadra and Nagar Haveli": [
        "Dadra and Nagar Haveli",
        "other"
    ],
    "Daman and Diu": [
        "Daman",
        "Diu",
        "other"
    ],
    "Delhi": [
        "Central Delhi",
        "East Delhi",
        "New Delhi",
        "North Delhi",
        "North East Delhi",
        "North West Delhi",
        "South Delhi",
        "South West Delhi",
        "West Delhi",
        "other"        
    ],
    "Goa": [
        "North Goa",
        "South Goa",
        "other"
    ],
    "Gujarat": [
        "Ahmedabad",
        "Amreli",
        "Anand",
        "Aravalli",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhota Udepur",
        "Dahod",
        "Dangs",
        "Devbhoomi Dwarka",
        "Gandhinagar",
        "Gir Somnath",
        "Jamnagar",
        "Junagadh",
        "Kachchh",
        "Kheda",
        "Mahisagar",
        "Mehsana",
        "Morbi",
        "Narmada",
        "Navsari",
        "Panchmahal",
        "Patan",
        "Porbandar",
        "Rajkot",
        "Sabarkantha",
        "Surat",
        "Surendranagar",
        "Tapi",
        "Vadodara",
        "Valsad",
        "other"
    ],
    "Haryana": [
        "Ambala",
        "Bhiwani",
        "Charkhi Dadri",
        "Faridabad",
        "Fatehabad",
        "Gurgaon",
        "Hissar",
        "Jhajjar",
        "Jind",
        "Karnal",
        "Kaithal",
        "Kurukshetra",
        "Mahendragarh",
        "Mewat",
        "Palwal",
        "Panchkula",
        "Panipat",
        "Rewari",
        "Rohtak",
        "Sirsa",
        "Sonipat",
        "Yamuna Nagar",
        "other"
    ],
    "Himachal Pradesh": [
        "Bilaspur",
        "Chamba",
        "Hamirpur",
        "Kangra",
        "Kinnaur",
        "Kullu",
        "Lahaul and Spiti",
        "Mandi",
        "Shimla",
        "Sirmaur",
        "Solan",
        "Una",
        "other"
    ],
    "Jammu and Kashmir": [
        "Anantnag",
        "Badgam",
        "Bandipora",
        "Baramulla",
        "Doda",
        "Ganderbal",
        "Jammu",
        "Kargil",
        "Kathua",
        "Kishtwar",
        "Kupwara",
        "Kulgam",
        "Leh",
        "Poonch",
        "Pulwama",
        "Rajauri",
        "Ramban",
        "Reasi",
        "Samba",
        "Shopian",
        "Srinagar",
        "Udhampur",
        "other"
    ],
    "Jharkhand": [
        "Bokaro",
        "Chatra",
        "Deoghar",
        "Dhanbad",
        "Dumka",
        "East Singhbhum",
        "Garhwa",
        "Giridih",
        "Godda",
        "Gumla",
        "Hazaribag",
        "Jamtara",
        "Khunti",
        "Koderma",
        "Latehar",
        "Lohardaga",
        "Pakur",
        "Palamu",
        "Ramgarh",
        "Ranchi",
        "Sahibganj",
        "Seraikela Kharsawan",
        "Simdega",
        "West Singhbhum",
        "other"
    ],
    "Karnataka": [
        "Bagalkot",
        "Bangalore Rural",
        "Bangalore Urban",
        "Belgaum",
        "Bellary",
        "Bidar",
        "Bijapur",
        "Chamarajnagar",
        "Chikkamagaluru",
        "Chikkaballapur",
        "Chitradurga",
        "Davanagere",
        "Dharwad",
        "Dakshina Kannada",
        "Gadag",
        "Gulbarga",
        "Hassan",
        "Haveri district",
        "Kodagu",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysore",
        "Raichur",
        "Shimoga",
        "Tumkur",
        "Udupi",
        "Uttara Kannada",
        "Ramanagara",
        "Yadgir",
        "other"
    ],
    "Kerala": [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasaragod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Palakkad",
        "Pathanamthitta",
        "Thrissur",
        "Thiruvananthapuram",
        "Wayanad",
        "other"
    ],
    "Madhya Pradesh": [
        "Agar Malwa",
        "Alirajpur",
        "Anuppur",
        "Ashoknagar",
        "Balaghat",
        "Barwani",
        "Betul",
        "Bhind",
        "Bhopal",
        "Burhanpur",
        "Chhatarpur",
        "Chhindwara",
        "Damoh",
        "Datia",
        "Dewas",
        "Dhar",
        "Dindori",
        "Guna",
        "Gwalior",
        "Harda",
        "Hoshangabad",
        "Indore",
        "Jabalpur",
        "Jhabua",
        "Katni",
        "Khandwa",
        "Khargone",
        "Mandla",
        "Mandsaur",
        "Morena",
        "Narsinghpur",
        "Neemuch",
        "Panna",
        "Raisen",
        "Rajgarh",
        "Ratlam",
        "Rewa",
        "Sagar",
        "Satna",
        "Sehore",
        "Seoni",
        "Shahdol",
        "Shajapur",
        "Sheopur",
        "Shivpuri",
        "Sidhi",
        "Singrauli",
        "Tikamgarh",
        "Ujjain",
        "Umaria",
        "Vidisha",
        "other"
    ],
    "Maharashtra": [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Bhandara",
        "Beed",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai suburban",
        "Nandurbar",
        "Nanded",
        "Nagpur",
        "Nashik",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sindhudurg",
        "Sangli",
        "Solapur",
        "Satara",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal",
        "other"
    ],
    "Manipur": [
        "Bishnupur",
        "Churachandpur",
        "Chandel",
        "Imphal East",
        "Senapati",
        "Tamenglong",
        "Thoubal",
        "Ukhrul",
        "Imphal West",
        "other"
    ],
    "Meghalaya": [
        "East Garo Hills",
        "East Khasi Hills",
        "Jaintia Hills",
        "Ri Bhoi",
        "South Garo Hills",
        "West Garo Hills",
        "West Khasi Hills",
        "other"
    ],
    "Mizoram": [
        "Dimapur",
        "Kohima",
        "Mokokchung",
        "Mon",
        "Phek",
        "Tuensang",
        "Wokha",
        "Zunheboto",
        "other"
    ],
    "Orissa": [
        "Angul",
        "Boudh (Bauda)",
        "Bhadrak",
        "Balangir",
        "Bargarh (Baragarh)",
        "Balasore",
        "Cuttack",
        "Debagarh (Deogarh)",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghpur",
        "Khordha",
        "Kendujhar (Keonjhar)",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur (Sonepur)",
        "Sundergarh",
        "other"
    ],
    "Pondicherry": [
        "Karaikal",
        "Mahe",
        "Pondicherry",
        "Yanam",
        "other"
    ],
    "Punjab": [
        "Amritsar",
        "Barnala",
        "Bathinda",
        "Firozpur",
        "Faridkot",
        "Fatehgarh Sahib",
        "Fazilka",
        "Gurdaspur",
        "Hoshiarpur",
        "Jalandhar",
        "Kapurthala",
        "Ludhiana",
        "Mansa",
        "Moga",
        "Sri Muktsar Sahib",
        "Pathankot",
        "Patiala",
        "Rupnagar",
        "Ajitgarh (Mohali)",
        "Sangrur",
        "Nawanshahr",
        "Tarn Taran",
        "other"
    ],
    "Rajasthan": [
        "Ajmer",
        "Alwar",
        "Banswara",
        "Baran",
        "Barmer",
        "Bharatpur",
        "Bhilwara",
        "Bikaner",
        "Bundi",
        "Chittorgarh",
        "Churu",
        "Dausa",
        "Dholpur",
        "Dungarpur",
        "Hanumangarh",
        "Jaipur",
        "Jaisalmer",
        "Jalore",
        "Jhalawar",
        "Jhunjhunu",
        "Jodhpur",
        "Karauli",
        "Kota",
        "Nagaur",
        "Pali",
        "Pratapgarh",
        "Rajsamand",
        "Sawai Madhopur",
        "Sikar",
        "Sirohi",
        "Sri Ganganagar",
        "Tonk",
        "Udaipur",
        "other"
    ],
    "Sikkim": [
        "East Sikkim",
        "North Sikkim",
        "South Sikkim",
        "West Sikkim",
        "other"
    ],
    "Tamil Nadu": [
        "Ariyalur",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Madurai",
        "Nagapattinam",
        "Nilgiris",
        "Namakkal",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Salem",
        "Sivaganga",
        "Tirupur",
        "Tiruchirappalli",
        "Theni",
        "Tirunelveli",
        "Thanjavur",
        "Thoothukudi",
        "Tiruvallur",
        "Tiruvarur",
        "Tiruvannamalai",
        "Vellore",
        "Viluppuram",
        "Virudhunagar",
        "other"
    ],
    "Telangana": [
        "Adilabad",
        "Bhadradri Kothagudem",
        "Hyderabad",
        "Jagtial",
        "Jangaon",
        "Jayashankar Bhoopalpally",
        "Jogulamba Gadwal",
        "Kamareddy",
        "Karimnagar",
        "Khammam",
        "Komaram Bheem Asifabad",
        "Mahabubabad",
        "Mahabubnagar",
        "Mancherial",
        "Medak",
        "Medchal",
        "Nagarkurnool",
        "Nalgonda",
        "Nirmal",
        "Nizamabad",
        "Peddapalli",
        "Rajanna Sircilla",
        "Rangareddy",
        "Sangareddy",
        "Siddipet",
        "Suryapet",
        "Vikarabad",
        "Wanaparthy",
        "Warangal",
        "Yadadri Bhuvanagiri"
    ],
    "Tripura": [
        "Dhalai",
        "North Tripura",
        "South Tripura",
        "Khowai",
        "West Tripura",
        "other"
    ],
    "Uttar Pradesh": [
        "Agra",
        "Allahabad",
        "Aligarh",
        "Ambedkar Nagar",
        "Auraiya",
        "Azamgarh",
        "Barabanki",
        "Budaun",
        "Bagpat",
        "Bahraich",
        "Bijnor",
        "Ballia",
        "Banda",
        "Balrampur",
        "Bareilly",
        "Basti",
        "Bulandshahr",
        "Chandauli",
        "Chhatrapati Shahuji Maharaj Nagar",
        "Chitrakoot",
        "Deoria",
        "Etah",
        "Kanshi Ram Nagar",
        "Etawah",
        "Firozabad",
        "Farrukhabad",
        "Fatehpur",
        "Faizabad",
        "Gautam Buddh Nagar",
        "Gonda",
        "Ghazipur",
        "Gorakhpur",
        "Ghaziabad",
        "Hamirpur",
        "Hardoi",
        "Mahamaya Nagar",
        "Jhansi",
        "Jalaun",
        "Jyotiba Phule Nagar",
        "Jaunpur district",
        "Ramabai Nagar (Kanpur Dehat)",
        "Kannauj",
        "Kanpur",
        "Kaushambi",
        "Kushinagar",
        "Lalitpur",
        "Lakhimpur Kheri",
        "Lucknow",
        "Mau",
        "Meerut",
        "Maharajganj",
        "Mahoba",
        "Mirzapur",
        "Moradabad",
        "Mainpuri",
        "Mathura",
        "Muzaffarnagar",
        "Panchsheel Nagar district (Hapur)",
        "Pilibhit",
        "Shamli",
        "Pratapgarh",
        "Rampur",
        "Raebareli",
        "Saharanpur",
        "Sitapur",
        "Shahjahanpur",
        "Sant Kabir Nagar",
        "Siddharthnagar",
        "Sonbhadra",
        "Sant Ravidas Nagar",
        "Sultanpur",
        "Shravasti",
        "Unnao",
        "Varanasi",
        "other"
    ],
    "Uttarakhand": [
        "Almora",
        "Bageshwar",
        "Chamoli",
        "Champawat",
        "Dehradun",
        "Haridwar",
        "Nainital",
        "Pauri Garhwal",
        "Pithoragarh",
        "Rudraprayag",
        "Tehri Garhwal",
        "Udham Singh Nagar",
        "Uttarkashi",
        "other"
    ],
    "West Bengal": [
        "Birbhum",
        "Bankura",
        "Bardhaman",
        "Darjeeling",
        "Dakshin Dinajpur",
        "Hooghly",
        "Howrah",
        "Jalpaiguri",
        "Cooch Behar",
        "Kolkata",
        "Maldah",
        "Paschim Medinipur",
        "Purba Medinipur",
        "Murshidabad",
        "Nadia",
        "North 24 Parganas",
        "South 24 Parganas",
        "Purulia",
        "Uttar Dinajpur",
        "other"
    ]
}


let allStates = [...Object.keys(stateObject)]
let allStateWithCities = stateObject


const ShowTalukaPage = () => {

    useEffect(() => {
        setDistrictList(allStateWithCities['Gujarat'])
    }, [])

    const [districtList, setDistrictList] = useState([])
    const [state, setState] = useState('Gujarat')
    const [district, setDistrict] = useState('Ahmedabad')
    const [cityList, setCityList] = useState([])

    const [search, setSearch] = useState(false)
    const [loading, setLoading] = useState(false)
    const handleStateChange = (e) =>{
        setState(e.target.value)
        setDistrict(allStateWithCities[e.target.value][0])
        setDistrictList(allStateWithCities[e.target.value])
    }

    const handleDistrict = e =>{
        setDistrict(e.target.value)
    }

    const submit = e =>{
        e.preventDefault()
        setLoading(true)
        let formData = new FormData()
        formData.append('getcity', 'getcity')
        formData.append('state', state)
        formData.append('dist', district)

        fetch('https://darjihelpline.com/dhlapi/RegistrationControllerJson.php', {
            method: 'POST',
            body: formData
        }).then(res => {
            return res.json()
        }).then((data) => {
            console.log(data)
            setLoading(false)
            if(data.success === 200){
               setCityList([...data.de]) 
               setSearch(true)
            }else{
               setCityList([]) 
               setSearch(true)
            }
        })
    }
    console.log(state, district, 'sd')

    return (
        <>
            <Navbar />
            <div style={{marginTop: 100}}></div>
            <section className="section-content padding-y">
                <div className="mx-3 card bg-white py-3 mx-md-auto" style={{maxWidth: 520, marginTop: 40}}>
                    <article className="card-body">
                        <header className="mb-5"><h4 className="card-title text-center">Search Taluka of District</h4></header>
                    </article>
                    <form>
                        <div className="form-row">
                            <div className="form-group col-9 mx-auto">
                                <label>State</label>
                                <select onChange={handleStateChange} id="inputState" className="form-control">
                                    {
                                        allStates.map((x,i) => <option selected={x === 'Gujarat' ? true : false}>{x}</option>)
                                    }
                                </select>  
                            </div>
                            <div className="form-group col-9 mx-auto">
                                <label>District</label>
                                <select onChange={handleDistrict} id="inputState" className="form-control">
                                    {
                                        districtList.map((x,i) => <option>{x}</option>)
                                    }
                                </select>  
                            </div>
                        </div>
                        {
                            !loading && 
                            <div className="form-group ml-5">
                                <button onClick={submit} style={{backgroundColor: '#F8C400', color: 'white'}} type="submit" className="btn mx-auto col-9 btn-block"> Add Volunteer  </button>
                            </div>
                        }
                        {
                            loading &&
                            <div style={{marginLeft: '40%'}} className="py-3">
                                <Loader type="Oval" height={50} color="#F8C400"/>
                            </div>
                        }
                    </form>
                    <div className="mx-auto">
                        {cityList.length === 0 && search && <h4 className="col-9 mx-auto">No taluka available in this district</h4>}
                        {
                            cityList.map((x, i) => <p>{i}. {x.city}</p>)
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShowTalukaPage
