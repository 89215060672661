import { useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router"

const LoginPage = (props) => {

    const [input, setInput] = useState({
        mobile: '',
        password: ''
    })
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    useEffect(() => {
        if(localStorage.getItem('dhl') !== null){
            props.history.push('/')
        }
    }, [props.history])

    const login = (e) =>{
        e.preventDefault()
        if(input.mobile.length !== 10){
            setError('Mobile length should 10 digit')
            return
        }
        if(input.password === ""){
            setError('Please enter password')
            return
        }

        setLoading(true)
        let formData = new FormData()
        formData.append('checklogin', 'checklogin')
        formData.append('mobileno', input.mobile)
        formData.append('pass', input.password)
        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
            setLoading(false)
            if(data.success === 200){
                localStorage.setItem("dhl", JSON.stringify({
                    mobile: input.mobile
                }))
                props.history.push('/')
            }else{
                setError('Invalid Credintials')
            }
        })
    }

    return (
        <section className="section-content padding-y">
            <div className="mx-3 card bg-white py-3 mx-md-auto" style={{maxWidth: 520, marginTop: 40}}>
                <article className="card-body">
                    <header className="mb-4"><h4 className="card-title text-center">Login</h4></header>
                    {error !== "" && <h4 style={{color: 'red'}} className="text-center my-3">{error}</h4>}
                    <form>
                        <div className="form-row">
                            <div className="col form-group">
                                <label>Mobile</label>
                                <input onChange={(e) => {setInput({...input, mobile: e.target.value}); setError('')}} value={input.mobile} type="number"  minLength="10" className="form-control" placeholder="Enter Your Mobile" required/>
                            </div> 
                        </div>
                        <div className="form-row">
                            <div className="col form-group">
                                <label>Password</label>
                                <input type="text" onChange={(e) => {setInput({...input, password: e.target.value}); setError('')}} value={input.password} className="form-control" placeholder="Enter Your Password" required/>
                            </div> 
                        </div>
                        {
                            !loading && 
                            <div className="form-group">
                                <button style={{backgroundColor: '#F8C400', color: 'white'}} onClick={login} type="submit" className="btn mx-auto btn-block"> Login  </button>
                            </div>
                        }
                        {
                            loading &&
                            <div style={{marginLeft: '40%'}} className="py-3">
                                <Loader type="Oval" height={50} color="#F8C400"/>
                            </div>
                        }
                    </form>
                </article>
            </div>
        </section>        
    )
}

export default withRouter(LoginPage)
