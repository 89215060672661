import { Link } from "react-router-dom"

const Navbar = () => {
    return (
        <section className="menu cid-qTkzRZLJNu" once="menu" id="menu1-0">
            <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div className="menu-logo">
                    <div className="navbar-brand">
                        <span className="navbar-logo">
                            <a href="https://darjihelpline.com" rel="noreferrer" target="_blank">
                                <img src="dhl.jpg" alt="darji helpline" title="" style={{height: '4rem'}} />
                            </a>
                        </span>
                        <span className="navbar-caption-wrap">
                            <a className="navbar-caption text-white display-4" href="https://hinduhelpline.in" >Helpline Admin</a>
                        </span>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/" ><span className="mbri-home mbr-iconfont mbr-iconfont-btn"></span>Home &nbsp; &nbsp; &nbsp; &nbsp;</Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/helps" ><span className="mbri-cust-feedback mbr-iconfont mbr-iconfont-btn"></span>Helps &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/display-users" ><span className="mbri-users mbr-iconfont mbr-iconfont-btn"></span>Users &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/display-volunteer" ><span className="mbri-user mbr-iconfont mbr-iconfont-btn"></span>Volunteers &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/add-volunteer" ><span className="mbri-plus mbr-iconfont mbr-iconfont-btn"></span>Add Volunteer &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/add-taluka" ><span className="mbri-plus mbr-iconfont mbr-iconfont-btn"></span>Add Taluka &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link onClick={() => {
                                localStorage.removeItem("dhl");
                            }} className="nav-link link text-white display-4" to="/add-volunteer" ><span className="mbri-close mbr-iconfont mbr-iconfont-btn"></span>Logout &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                    </ul>
                </div>
            </nav>
        </section>
    )
}

export default Navbar
