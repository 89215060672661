import { useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router"
import Navbar from '../components/Navbar'

const DisplayHelpsPage = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [selectedType, setSelectedType] = useState("All")
    const [helpList, setHelpList] = useState([])
    const [copy, setCopy] = useState([])
    const [input, setInput] = useState('')

    useEffect(() => {
        if(localStorage.getItem('dhl') === null){
            props.history.push('/login')
        }
        setIsLoading(true)
        const formData = new FormData()
        formData.append('allhelp', 'allhelp')
        
        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
            setIsLoading(false)
            if(data.success === 200){
                console.log(data.de)
                setHelpList(data.de)
                setCopy(data.de)
            }else{
                setHelpList([])
            }
        })
    }, [props.history])

    const filterHelp = (e) =>{

        setIsLoading(true)
        setSelectedType(e.target.value)
        if(e.target.value === "All"){
            const formData = new FormData()
            formData.append('allhelp', 'allhelp')
            
            fetch('https://darjihelpline.com/dhlapi/webapi.php', {
                method: 'POST',
                body: formData
            }).then(res => res.json()).then((data) => {
                setIsLoading(false)
                if(data.success === 200){
                    setHelpList(data.de)
                    setCopy(data.de)
                }else{
                    setHelpList([])
                }
            })
        }else{
            const formData = new FormData()
            if(e.target.value === 'New'){
                formData.append('helpadminsuperr', 'helpadminsuperr')
            }

            if(e.target.value === 'Pending'){
                formData.append('helpadpendsuperr', 'helpadpendsuperr')
            }
            
            if(e.target.value === 'Completed'){
                formData.append('helpadcmpsuperr', 'helpadcmpsuperr')
            }

            fetch('https://darjihelpline.com/dhlapi/help.php', {
                method: 'POST',
                body: formData
            }).then(res => res.json()).then((data) => {
                setIsLoading(false)
                if(data.success === 200){
                    setHelpList(data.de)
                    setCopy(data.de)
                }else{
                    setHelpList([])
                }
            })
        }
    }

    const helpAppering = (id) => {
        setIsLoading(true)
        let formData = new FormData()
        formData.append('helpone', 'helpone')
        formData.append('hid', id)
        formData.append('status', '1')
        formData.append('name', 'Niraj Vaghela')
        formData.append('mobno', '9429409286')

        fetch('https://darjihelpline.com/dhlapi/help.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
           
            if(data.success === 200){

                const formData1 = new FormData()
                formData1.append('helpadminsuperr', 'helpadminsuperr')
                fetch('https://darjihelpline.com/dhlapi/help.php', {
                    method: 'POST',
                    body: formData1
                }).then(res => res.json()).then((data) => {
                    setIsLoading(false)
                    if(data.success === 200){
                        setHelpList(data.de)
                        setCopy(data.de)
                    }else{
                        setHelpList([])
                    }
                })
            }
        })
    }

    const helpSolve = (id) => {
        setIsLoading(true)
        let formData = new FormData()
        formData.append('helptwo', 'helptwo')
        formData.append('hid', id)
        formData.append('status', '2')

        fetch('https://darjihelpline.com/dhlapi/help.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
           
            if(data.success === 200){

                const formData1 = new FormData()
                formData1.append('helpadpendsuperr', 'helpadpendsuperr')
                fetch('https://darjihelpline.com/dhlapi/help.php', {
                    method: 'POST',
                    body: formData1
                }).then(res => res.json()).then((data) => {
                    setIsLoading(false)
                    if(data.success === 200){
                        setHelpList(data.de)
                        setCopy(data.de)
                    }else{
                        setHelpList([])
                    }
                })
            }
        })
    }

    const search = (e) => {
        if(input === ""){
            setHelpList(copy)
        }else{
            let newList = copy.filter((x) => (x.id === input || x.mobileno === input))
            setHelpList(newList)
        }
    }

    return (
        <>
         <Navbar />
        <div style={{marginTop: 100}}></div>
        <div>
            {
                isLoading &&
                <div className="col-12 row">
                    <div className="mx-auto card bg-white p-5" style={{marginTop: '15%'}}>
                        <Loader type="Circles" color="#EC9F2C"/>
                    </div>
                </div>  
            }
            {
                !isLoading && 
                <div>
                    {
                        copy.length !== 0 &&
                        <div className="row mx-3 bg-white py-3">
                        <div className="form-group col-md-4 card bg-white p-3 mx-auto">
                            <label><p style={{fontWeight: 'bold'}}>Filter Help</p></label>
                            <select value={selectedType} onChange={(e) => filterHelp(e)} className="form-control" aria-label=".form-select-lg example">
                                <option>All</option>
                                <option>New</option>
                                <option>Pending</option>
                                <option>Completed</option>
                            </select>
                        </div>
                        <div className="col-md-4 ">
                            <label><p style={{fontWeight: 'bold'}}>Search Help</p></label>
                            <input value={input} onChange={e => setInput(e.target.value)} placeholder="Enter Help ID or Mobile" className="form-control my-3" />
                        </div>
                        <div className="col-md-3">
                            <button onClick={search} className="btn btn-outline-info col-9 mx-auto">Find</button>
                            <button onClick={() => setHelpList(copy)} className="btn btn-outline-info col-9 mx-auto">Clear Search</button>
                        </div>
        
                    </div>
                    }
                    {/* <div className="form-group col-md-3 card bg-white p-3 mx-auto">
                        <label><p style={{fontWeight: 'bold'}}>Filter Help</p></label>
                        <select value={selectedType} onChange={(e) => filterHelp(e)} className="form-control" aria-label=".form-select-lg example">
                            <option>All</option>
                            <option>New</option>
                            <option>Pending</option>
                            <option>Completed</option>
                        </select>
                        <input value={input} onChange={e => setInput(e.target.value)} placeholder="Enter Help ID or Mobile" className="form-control my-3" />
                        <button onClick={search} className="btn btn-outline-info col-9 mx-auto">Find</button>
                        <button onClick={() => setHelpList(copy)} className="btn btn-outline-info col-9 mx-auto">Clear Search</button>
                    </div> */}
                    {helpList.length === 0 && <h1 className="text-center mt-5">0 Help Found</h1>}
                    <div className="row col-md-9 mx-auto">
                        {
                            helpList.length > 0 &&
                            helpList.map((help, i) => {
                                return(
                                    <div key={i} className="col-md-6 my-3">
                                        <div className="card bg-white">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <h4 className="card-title">Your Help Details {i === 0 && <div><br></br><h4 style={{color: 'green'}}>Latest</h4></div>}</h4>
                                                    </div>
                                                    <div className="col-6">
                                                        {selectedType === "New" && <button className="" onClick={() => helpAppering(help.id)} style={{backgroundColor: '#f8c400', color: 'white', padding: 10, borderColor: 'white', borderRadius: 5, fontWeight: 'bold'}}>HELP APPERING</button>}
                                                        {selectedType === "Pending" && <button className="" onClick={() => helpSolve(help.id)} style={{backgroundColor: '#f8c400', color: 'white', padding: 10, borderColor: 'white', borderRadius: 5, fontWeight: 'bold'}}>HELP SOLVE</button>}
                                                    </div>
                                                </div>
                                                <hr></hr>
                                                <p className="card-text h6">Help Number: {help.id}</p>
                                                <br></br>
                                                <p className="card-text h6">Your Name: {help.fullname}</p>
                                                <br></br>
                                                <p className="card-text h6">Your Mobile: {help.mobileno}</p>
                                                <br></br>
                                                <p className="card-text h6">Help Date: {help.datehelp}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Mode: {help.helpmode}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Type: {help.typehelp} ({help.subhelp})</p>   
                                                <br></br>
                                                <p className="card-text h6">Help State: {help.state}</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Location: {help.dist} ({help.city})</p>   
                                                <br></br>
                                                <p className="card-text h6">Help Details: <br></br><br></br> {help.hdisc}</p>
                                            </div>
                                            <div className="card-body">
                                                <h4 className="card-title">Volunteer Details</h4>
                                                <hr></hr>
                                                <p style={{color: (help.status === '0' || help.status === '1') ? 'red' : 'green' }} className="card-text h6">Help Status: {(help.status === '0' || help.status === '1') ? 'Pending' : 'Completed'}</p>
                                                <br></br>
                                                <p className="card-text h6">Volunteer Name: {help.helper}</p>
                                                <br></br>
                                                <p className="card-text h6">Volunteer's Mobile: {help.hmob}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>   
            }          
        </div>
        </>
    )
}

export default withRouter(DisplayHelpsPage)
