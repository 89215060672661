import { useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router"
import Navbar from '../components/Navbar'

const HomePage = (props) => {

    const [isLoading, setIsLoading] = useState(false)
    const [count, setCount] = useState({})

    useEffect(() => {
        if(localStorage.getItem('dhl') === null){
            props.history.push('/login')
        }
        setIsLoading(true)

        let formData = new FormData()
        formData.append('count', 'count')

        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
            setIsLoading(false)
            if(data.success === 200){
                setCount(data.de[0])
                console.log(data.de[0])
            }else{
                setCount({})
            }
        })
    }, [props.history])

    return(
        <>
        <Navbar />
        <div style={{marginTop: 100}}></div>
        <div className="row col-md-12 mt-3">
            <div className="card col-md-3 mt-3">
                <div style={{backgroundColor: '#ffffff'}}>
                    <h3 className="text-center my-3">Total Helps</h3>
                    {!isLoading && <h1 className="text-center my-3">{count.total}</h1>}
                    {isLoading && <h1 className="text-center"><Loader type="ThreeDots" color="#F6B731"/></h1>}
                </div>
            </div>
            <div className="card col-md-3 mt-3">
                <div style={{backgroundColor: '#ffffff'}}>
                    <h3 className="text-center my-3">New Help</h3>
                    {!isLoading && <h1 className="text-center my-3">{count.neww}</h1>}
                    {isLoading && <h1 className="text-center"><Loader type="ThreeDots" color="#F6B731"/></h1>}
                </div>
            </div>
            <div className="card col-md-3 mt-3">
                <div style={{backgroundColor: '#ffffff'}}>
                    <h3 className="text-center my-3">Pending Help</h3>
                    {!isLoading && <h1 className="text-center my-3">{count.pendd}</h1>}
                    {isLoading && <h1 className="text-center"><Loader type="ThreeDots" color="#F6B731"/></h1>}
                </div>
            </div>
            <div className="card col-md-3 mt-3">
                <div style={{backgroundColor: '#ffffff'}}>
                <h3 className="text-center my-3">Completed Help</h3>
                    {!isLoading && <h1 className="text-center my-3">{count.cmpp}</h1>}
                    {isLoading && <h1 className="text-center"><Loader type="ThreeDots" color="#F6B731"/></h1>}
                </div>
            </div>
        </div>
        </>
    )
}

export default withRouter(HomePage)