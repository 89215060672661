import { useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router"
import Navbar from '../components/Navbar'

let stateObject = {
    "Andhra Pradesh": [
        "Adilabad",
        "Anantapur",
        "Chittoor",
        "Kakinada",
        "Guntur",
        "Hyderabad",
        "Karimnagar",
        "Khammam",
        "Krishna",
        "Kurnool",
        "Mahbubnagar",
        "Medak",
        "Nalgonda",
        "Nizamabad",
        "Ongole",
        "Hyderabad",
        "Srikakulam",
        "Nellore",
        "Visakhapatnam",
        "Vizianagaram",
        "Warangal",
        "Eluru",
        "Kadapa",
        "other"
    ],
    "Arunachal Pradesh": [
        "Anjaw",
        "Changlang",
        "East Siang",
        "Kurung Kumey",
        "Lohit",
        "Lower Dibang Valley",
        "Lower Subansiri",
        "Papum Pare",
        "Tawang",
        "Tirap",
        "Dibang Valley",
        "Upper Siang",
        "Upper Subansiri",
        "West Kameng",
        "West Siang",
        "other"
    ],
    "Assam": [
        "Baksa",
        "Barpeta",
        "Bongaigaon",
        "Cachar",
        "Chirang",
        "Darrang",
        "Dhemaji",
        "Dima Hasao",
        "Dhubri",
        "Dibrugarh",
        "Goalpara",
        "Golaghat",
        "Hailakandi",
        "Jorhat",
        "Kamrup",
        "Kamrup Metropolitan",
        "Karbi Anglong",
        "Karimganj",
        "Kokrajhar",
        "Lakhimpur",
        "Marigaon",
        "Nagaon",
        "Nalbari",
        "Sibsagar",
        "Sonitpur",
        "Tinsukia",
        "Udalguri",
        "other"
    ],
    "Bihar": [
        "Araria",
        "Arwal",
        "Aurangabad",
        "Banka",
        "Begusarai",
        "Bhagalpur",
        "Bhojpur",
        "Buxar",
        "Darbhanga",
        "East Champaran",
        "Gaya",
        "Gopalganj",
        "Jamui",
        "Jehanabad",
        "Kaimur",
        "Katihar",
        "Khagaria",
        "Kishanganj",
        "Lakhisarai",
        "Madhepura",
        "Madhubani",
        "Munger",
        "Muzaffarpur",
        "Nalanda",
        "Nawada",
        "Patna",
        "Purnia",
        "Rohtas",
        "Saharsa",
        "Samastipur",
        "Saran",
        "Sheikhpura",
        "Sheohar",
        "Sitamarhi",
        "Siwan",
        "Supaul",
        "Vaishali",
        "West Champaran",
        "Chandigarh","other"
    ],
    "Chhattisgarh": [
        "Bastar",
        "Bijapur",
        "Bilaspur",
        "Dantewada",
        "Dhamtari",
        "Durg",
        "Jashpur",
        "Janjgir-Champa",
        "Korba",
        "Koriya",
        "Kanker",
        "Kabirdham (Kawardha)",
        "Mahasamund",
        "Narayanpur",
        "Raigarh",
        "Rajnandgaon",
        "Raipur",
        "Surguja","other"       
    ],
    "Dadra and Nagar Haveli": [
        "Dadra and Nagar Haveli",
        "other"
    ],
    "Daman and Diu": [
        "Daman",
        "Diu",
        "other"
    ],
    "Delhi": [
        "Central Delhi",
        "East Delhi",
        "New Delhi",
        "North Delhi",
        "North East Delhi",
        "North West Delhi",
        "South Delhi",
        "South West Delhi",
        "West Delhi",
        "other"        
    ],
    "Goa": [
        "North Goa",
        "South Goa",
        "other"
    ],
    "Gujarat": [
        "Ahmedabad",
        "Amreli",
        "Anand",
        "Aravalli",
        "Banaskantha",
        "Bharuch",
        "Bhavnagar",
        "Botad",
        "Chhota Udepur",
        "Dahod",
        "Dangs",
        "Devbhoomi Dwarka",
        "Gandhinagar",
        "Gir Somnath",
        "Jamnagar",
        "Junagadh",
        "Kachchh",
        "Kheda",
        "Mahisagar",
        "Mehsana",
        "Morbi",
        "Narmada",
        "Navsari",
        "Panchmahal",
        "Patan",
        "Porbandar",
        "Rajkot",
        "Sabarkantha",
        "Surat",
        "Surendranagar",
        "Tapi",
        "Vadodara",
        "Valsad",
        "other"
    ],
    "Haryana": [
        "Ambala",
        "Bhiwani",
        "Charkhi Dadri",
        "Faridabad",
        "Fatehabad",
        "Gurgaon",
        "Hissar",
        "Jhajjar",
        "Jind",
        "Karnal",
        "Kaithal",
        "Kurukshetra",
        "Mahendragarh",
        "Mewat",
        "Palwal",
        "Panchkula",
        "Panipat",
        "Rewari",
        "Rohtak",
        "Sirsa",
        "Sonipat",
        "Yamuna Nagar",
        "other"
    ],
    "Himachal Pradesh": [
        "Bilaspur",
        "Chamba",
        "Hamirpur",
        "Kangra",
        "Kinnaur",
        "Kullu",
        "Lahaul and Spiti",
        "Mandi",
        "Shimla",
        "Sirmaur",
        "Solan",
        "Una",
        "other"
    ],
    "Jammu and Kashmir": [
        "Anantnag",
        "Badgam",
        "Bandipora",
        "Baramulla",
        "Doda",
        "Ganderbal",
        "Jammu",
        "Kargil",
        "Kathua",
        "Kishtwar",
        "Kupwara",
        "Kulgam",
        "Leh",
        "Poonch",
        "Pulwama",
        "Rajauri",
        "Ramban",
        "Reasi",
        "Samba",
        "Shopian",
        "Srinagar",
        "Udhampur",
        "other"
    ],
    "Jharkhand": [
        "Bokaro",
        "Chatra",
        "Deoghar",
        "Dhanbad",
        "Dumka",
        "East Singhbhum",
        "Garhwa",
        "Giridih",
        "Godda",
        "Gumla",
        "Hazaribag",
        "Jamtara",
        "Khunti",
        "Koderma",
        "Latehar",
        "Lohardaga",
        "Pakur",
        "Palamu",
        "Ramgarh",
        "Ranchi",
        "Sahibganj",
        "Seraikela Kharsawan",
        "Simdega",
        "West Singhbhum",
        "other"
    ],
    "Karnataka": [
        "Bagalkot",
        "Bangalore Rural",
        "Bangalore Urban",
        "Belgaum",
        "Bellary",
        "Bidar",
        "Bijapur",
        "Chamarajnagar",
        "Chikkamagaluru",
        "Chikkaballapur",
        "Chitradurga",
        "Davanagere",
        "Dharwad",
        "Dakshina Kannada",
        "Gadag",
        "Gulbarga",
        "Hassan",
        "Haveri district",
        "Kodagu",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysore",
        "Raichur",
        "Shimoga",
        "Tumkur",
        "Udupi",
        "Uttara Kannada",
        "Ramanagara",
        "Yadgir",
        "other"
    ],
    "Kerala": [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasaragod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Palakkad",
        "Pathanamthitta",
        "Thrissur",
        "Thiruvananthapuram",
        "Wayanad",
        "other"
    ],
    "Madhya Pradesh": [
        "Agar Malwa",
        "Alirajpur",
        "Anuppur",
        "Ashoknagar",
        "Balaghat",
        "Barwani",
        "Betul",
        "Bhind",
        "Bhopal",
        "Burhanpur",
        "Chhatarpur",
        "Chhindwara",
        "Damoh",
        "Datia",
        "Dewas",
        "Dhar",
        "Dindori",
        "Guna",
        "Gwalior",
        "Harda",
        "Hoshangabad",
        "Indore",
        "Jabalpur",
        "Jhabua",
        "Katni",
        "Khandwa",
        "Khargone",
        "Mandla",
        "Mandsaur",
        "Morena",
        "Narsinghpur",
        "Neemuch",
        "Panna",
        "Raisen",
        "Rajgarh",
        "Ratlam",
        "Rewa",
        "Sagar",
        "Satna",
        "Sehore",
        "Seoni",
        "Shahdol",
        "Shajapur",
        "Sheopur",
        "Shivpuri",
        "Sidhi",
        "Singrauli",
        "Tikamgarh",
        "Ujjain",
        "Umaria",
        "Vidisha",
        "other"
    ],
    "Maharashtra": [
        "Ahmednagar",
        "Akola",
        "Amravati",
        "Aurangabad",
        "Bhandara",
        "Beed",
        "Buldhana",
        "Chandrapur",
        "Dhule",
        "Gadchiroli",
        "Gondia",
        "Hingoli",
        "Jalgaon",
        "Jalna",
        "Kolhapur",
        "Latur",
        "Mumbai City",
        "Mumbai suburban",
        "Nandurbar",
        "Nanded",
        "Nagpur",
        "Nashik",
        "Osmanabad",
        "Palghar",
        "Parbhani",
        "Pune",
        "Raigad",
        "Ratnagiri",
        "Sindhudurg",
        "Sangli",
        "Solapur",
        "Satara",
        "Thane",
        "Wardha",
        "Washim",
        "Yavatmal",
        "other"
    ],
    "Manipur": [
        "Bishnupur",
        "Churachandpur",
        "Chandel",
        "Imphal East",
        "Senapati",
        "Tamenglong",
        "Thoubal",
        "Ukhrul",
        "Imphal West",
        "other"
    ],
    "Meghalaya": [
        "East Garo Hills",
        "East Khasi Hills",
        "Jaintia Hills",
        "Ri Bhoi",
        "South Garo Hills",
        "West Garo Hills",
        "West Khasi Hills",
        "other"
    ],
    "Mizoram": [
        "Dimapur",
        "Kohima",
        "Mokokchung",
        "Mon",
        "Phek",
        "Tuensang",
        "Wokha",
        "Zunheboto",
        "other"
    ],
    "Orissa": [
        "Angul",
        "Boudh (Bauda)",
        "Bhadrak",
        "Balangir",
        "Bargarh (Baragarh)",
        "Balasore",
        "Cuttack",
        "Debagarh (Deogarh)",
        "Dhenkanal",
        "Ganjam",
        "Gajapati",
        "Jharsuguda",
        "Jajpur",
        "Jagatsinghpur",
        "Khordha",
        "Kendujhar (Keonjhar)",
        "Kalahandi",
        "Kandhamal",
        "Koraput",
        "Kendrapara",
        "Malkangiri",
        "Mayurbhanj",
        "Nabarangpur",
        "Nuapada",
        "Nayagarh",
        "Puri",
        "Rayagada",
        "Sambalpur",
        "Subarnapur (Sonepur)",
        "Sundergarh",
        "other"
    ],
    "Pondicherry": [
        "Karaikal",
        "Mahe",
        "Pondicherry",
        "Yanam",
        "other"
    ],
    "Punjab": [
        "Amritsar",
        "Barnala",
        "Bathinda",
        "Firozpur",
        "Faridkot",
        "Fatehgarh Sahib",
        "Fazilka",
        "Gurdaspur",
        "Hoshiarpur",
        "Jalandhar",
        "Kapurthala",
        "Ludhiana",
        "Mansa",
        "Moga",
        "Sri Muktsar Sahib",
        "Pathankot",
        "Patiala",
        "Rupnagar",
        "Ajitgarh (Mohali)",
        "Sangrur",
        "Nawanshahr",
        "Tarn Taran",
        "other"
    ],
    "Rajasthan": [
        "Ajmer",
        "Alwar",
        "Banswara",
        "Baran",
        "Barmer",
        "Bharatpur",
        "Bhilwara",
        "Bikaner",
        "Bundi",
        "Chittorgarh",
        "Churu",
        "Dausa",
        "Dholpur",
        "Dungarpur",
        "Hanumangarh",
        "Jaipur",
        "Jaisalmer",
        "Jalore",
        "Jhalawar",
        "Jhunjhunu",
        "Jodhpur",
        "Karauli",
        "Kota",
        "Nagaur",
        "Pali",
        "Pratapgarh",
        "Rajsamand",
        "Sawai Madhopur",
        "Sikar",
        "Sirohi",
        "Sri Ganganagar",
        "Tonk",
        "Udaipur",
        "other"
    ],
    "Sikkim": [
        "East Sikkim",
        "North Sikkim",
        "South Sikkim",
        "West Sikkim",
        "other"
    ],
    "Tamil Nadu": [
        "Ariyalur",
        "Chennai",
        "Coimbatore",
        "Cuddalore",
        "Dharmapuri",
        "Dindigul",
        "Erode",
        "Kanchipuram",
        "Kanyakumari",
        "Karur",
        "Madurai",
        "Nagapattinam",
        "Nilgiris",
        "Namakkal",
        "Perambalur",
        "Pudukkottai",
        "Ramanathapuram",
        "Salem",
        "Sivaganga",
        "Tirupur",
        "Tiruchirappalli",
        "Theni",
        "Tirunelveli",
        "Thanjavur",
        "Thoothukudi",
        "Tiruvallur",
        "Tiruvarur",
        "Tiruvannamalai",
        "Vellore",
        "Viluppuram",
        "Virudhunagar",
        "other"
    ],
    "Telangana": [
        "Adilabad",
        "Bhadradri Kothagudem",
        "Hyderabad",
        "Jagtial",
        "Jangaon",
        "Jayashankar Bhoopalpally",
        "Jogulamba Gadwal",
        "Kamareddy",
        "Karimnagar",
        "Khammam",
        "Komaram Bheem Asifabad",
        "Mahabubabad",
        "Mahabubnagar",
        "Mancherial",
        "Medak",
        "Medchal",
        "Nagarkurnool",
        "Nalgonda",
        "Nirmal",
        "Nizamabad",
        "Peddapalli",
        "Rajanna Sircilla",
        "Rangareddy",
        "Sangareddy",
        "Siddipet",
        "Suryapet",
        "Vikarabad",
        "Wanaparthy",
        "Warangal",
        "Yadadri Bhuvanagiri"
    ],
    "Tripura": [
        "Dhalai",
        "North Tripura",
        "South Tripura",
        "Khowai",
        "West Tripura",
        "other"
    ],
    "Uttar Pradesh": [
        "Agra",
        "Allahabad",
        "Aligarh",
        "Ambedkar Nagar",
        "Auraiya",
        "Azamgarh",
        "Barabanki",
        "Budaun",
        "Bagpat",
        "Bahraich",
        "Bijnor",
        "Ballia",
        "Banda",
        "Balrampur",
        "Bareilly",
        "Basti",
        "Bulandshahr",
        "Chandauli",
        "Chhatrapati Shahuji Maharaj Nagar",
        "Chitrakoot",
        "Deoria",
        "Etah",
        "Kanshi Ram Nagar",
        "Etawah",
        "Firozabad",
        "Farrukhabad",
        "Fatehpur",
        "Faizabad",
        "Gautam Buddh Nagar",
        "Gonda",
        "Ghazipur",
        "Gorakhpur",
        "Ghaziabad",
        "Hamirpur",
        "Hardoi",
        "Mahamaya Nagar",
        "Jhansi",
        "Jalaun",
        "Jyotiba Phule Nagar",
        "Jaunpur district",
        "Ramabai Nagar (Kanpur Dehat)",
        "Kannauj",
        "Kanpur",
        "Kaushambi",
        "Kushinagar",
        "Lalitpur",
        "Lakhimpur Kheri",
        "Lucknow",
        "Mau",
        "Meerut",
        "Maharajganj",
        "Mahoba",
        "Mirzapur",
        "Moradabad",
        "Mainpuri",
        "Mathura",
        "Muzaffarnagar",
        "Panchsheel Nagar district (Hapur)",
        "Pilibhit",
        "Shamli",
        "Pratapgarh",
        "Rampur",
        "Raebareli",
        "Saharanpur",
        "Sitapur",
        "Shahjahanpur",
        "Sant Kabir Nagar",
        "Siddharthnagar",
        "Sonbhadra",
        "Sant Ravidas Nagar",
        "Sultanpur",
        "Shravasti",
        "Unnao",
        "Varanasi",
        "other"
    ],
    "Uttarakhand": [
        "Almora",
        "Bageshwar",
        "Chamoli",
        "Champawat",
        "Dehradun",
        "Haridwar",
        "Nainital",
        "Pauri Garhwal",
        "Pithoragarh",
        "Rudraprayag",
        "Tehri Garhwal",
        "Udham Singh Nagar",
        "Uttarkashi",
        "other"
    ],
    "West Bengal": [
        "Birbhum",
        "Bankura",
        "Bardhaman",
        "Darjeeling",
        "Dakshin Dinajpur",
        "Hooghly",
        "Howrah",
        "Jalpaiguri",
        "Cooch Behar",
        "Kolkata",
        "Maldah",
        "Paschim Medinipur",
        "Purba Medinipur",
        "Murshidabad",
        "Nadia",
        "North 24 Parganas",
        "South 24 Parganas",
        "Purulia",
        "Uttar Dinajpur",
        "other"
    ]
}

let allStates = ['Choose...', ...Object.keys(stateObject)]
let allStateWithCities = stateObject


const AddVolunteerPage = (props) => {

    const [input, setInput] = useState({
        fname: '',
        lname: '',
        mobile: '',
        email: ''
    })

    const [roleType, setRoleType] = useState('Choose...')
    const [state, setState] = useState('Choose...')
    const [dist, setDist] = useState('Choose...')
    const [city, setCity] = useState('Choose...')
    const [error, setError] = useState(false)

    const [districtList, setDistrictList] = useState([])
    const [cityList, setCityList] = useState([])

    const [dropdownError, setDropdownError] = useState({
        fname: false,
        lname: false,
        mobile: false,
        email: false,
        roleType: false,
        state: false,
        district: false,
        city: false
    })

    useEffect(() => {
        if(localStorage.getItem('dhl') === null){
            props.history.push('/login')
        }
    }, [props.history])

    const [loading, setLoading] = useState(false)

    const handleRole = (e) =>{
        setDropdownError({...dropdownError, roleType: false})
        setRoleType(e.target.value)
    }

    const handleStateChange = (e) =>{
        setDropdownError({...dropdownError, state: false})
        // console.log(e.target.value)
        setState(e.target.value)
        setDist('Choose...')
        if(e.target.value === "Choose..."){
            setDistrictList([])
        }else{
            let cities = ['Choose...', ...allStateWithCities[e.target.value]]
            setDistrictList(cities)
        }
        // console.log(cities)
    }

    const handleDistrictChange = (e) =>{
        setDropdownError({...dropdownError, district: false})
        let selectedDist = e.target.value
        setDist(selectedDist)
        setCity('Choose...')
        let formData = new FormData()
        formData.append('getcity', 'getcity')
        formData.append('state', state)
        formData.append('dist', selectedDist)

        fetch('https://darjihelpline.com/dhlapi/RegistrationControllerJson.php', {
            method: 'POST',
            body: formData
        }).then(res => {
            return res.json()
        }).then((data) => {
            // console.log(data)
            if(data.success === 200){
               setCityList([{city: 'Choose...'}, ...data.de]) 
            }else{
               setCityList([{city: 'Choose...'}]) 
            }
        })
    }

    const hanldeCityChange = (e) =>{
        setDropdownError({...dropdownError, city: false})
        setCity(e.target.value)
    }

    const handleInput = (e, key) => {
        setDropdownError({...dropdownError, [key]: false})
        setInput({...input, [key]: e.target.value})
    }

    const submit = (e) =>{
        e.preventDefault()
        setLoading(true)
        let obj = {...dropdownError}

        for (const key in input) {
            if(input[key] === ''){
                obj = {...obj, [key]: true}
            }
        }

        if(roleType === "Choose..."){
            obj = {...obj, roleType: true}
            setDropdownError(obj)
          
        }

        if(roleType === "State Admin" && state === "Choose..."){
            setDropdownError({...obj, state: true})
         
        }

        if(roleType === "District Admin"){

            if(state === "Choose..."){
                obj = {...obj, state: true}
            }

            if(dist === 'Choose...'){
                obj = {...obj, district: true}
            }

            setDropdownError(obj)
        
        }

        if(roleType === "City Admin"){
            // console.log(state, dist, city)
  
              if(state === "Choose..."){
                console.log('d')
                obj = {...obj, state: true}
            }

            if(dist === 'Choose...'){
                obj = {...obj, district: true}
            }

            if(city === 'Choose...'){
                obj = {...obj, city: true}
            }
            console.log(obj)
            setDropdownError(obj)
           
        }

        let validate = Object.values(obj).every(x => x === false)
        if(!validate){
            console.log('some error')
            setLoading(false)
            return
        }else{
            let formData = new FormData()

            formData.append('addadmin', 'addadmin')
            formData.append('fname', input.fname)
            formData.append('lname', input.lname)
            formData.append('mobileno', input.mobile)
            formData.append('email', input.email)
            if(roleType === "Super Admin"){
                formData.append('state', 'other')
                formData.append('dist', 'other')
                formData.append('city', 'other')
            }
            if(roleType === "State Admin"){
                formData.append('state', state)
                formData.append('dist', 'other')
                formData.append('city', 'other')
            }
            if(roleType === "District Admin"){
                formData.append('state', state)
                formData.append('dist', dist)
                formData.append('city', 'other')
            }
            if(roleType === "City Admin"){
                formData.append('state', state)
                formData.append('dist', dist)
                formData.append('city', city)
            }
            formData.append('pass', 'dhl2021')
            formData.append('active', '1')

            fetch('https://darjihelpline.com/dhlapi/webapi.php', {
                method: 'POST',
                body: formData
            }).then(res => res.json()).then((data) =>{
                if(data.success === 200){
                    props.history.push('/display-volunteer')
                    setLoading(false)
                    console.log(data)
                }else{
                    setError(true)
                    setLoading(false)
                    setTimeout(() => {
                        setError(false)
                    }, 2500)
                }
            })
        }
    }
    // console.log(state, dist, city)
    const getDropdownError = (key) =>{
        if(dropdownError[key]){
            return `${key} is required`
        }else{
            return ''
        }
    }

    return (
        <>
         <Navbar />
        <div style={{marginTop: 100}}></div>
        <section className="section-content padding-y">
            <div className="mx-3 card bg-white py-3 mx-md-auto" style={{maxWidth: 520, marginTop: 40}}>
                {error && <h3 style={{color: 'red'}} className="text-center">Something went wrong</h3>}
                <article className="card-body">
                    <header className="mb-5"><h4 className="card-title text-center">Add Volunteer</h4></header>
                    <form>
                        <div className="form-row">
                            <div className="col form-group">
                                <label>First Name</label>
                                <input onChange={(e) => handleInput(e, 'fname')} value={input.fname} type="text" className="form-control" placeholder="Enter Your Full Name" />
                                <small style={{color: 'red'}} className="form-text">{getDropdownError('fname')}</small>
                            </div> 
                            <div className="col form-group">
                                <label>Last Name</label>
                                <input onChange={(e) => handleInput(e, 'lname')} value={input.lname} type="text" className="form-control" placeholder="Enter Your Full Name" />
                                <small style={{color: 'red'}} className="form-text">{getDropdownError('lname')}</small>
                            </div>
                        </div>
                        <div className="form-group">
                            <label>Mobile</label>
                            <input onChange={(e) => handleInput(e, 'mobile')} value={input.mobile} type="number" className="form-control" placeholder="Enter Your Mobile"/>
                            <small style={{color: 'red'}} className="form-text">{getDropdownError('mobile')}</small>
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input onChange={(e) => handleInput(e, 'email')} value={input.email} type="email" className="form-control" placeholder="Enter Your Email"/>
                            <small style={{color: 'red'}} className="form-text">{getDropdownError('email')}</small>
                        </div>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label>Role</label>
                                <select onChange={handleRole} id="inputState" className="form-control">
                                    <option>Choose...</option>
                                    <option>Super Admin</option>
                                    <option>State Admin</option>
                                    <option>District Admin</option>
                                    <option>City Admin</option>
                                </select>
                                <small style={{color: 'red'}} className="form-text">{getDropdownError('roleType')}</small>   
                            </div>
                        </div> 
                        <div className="form-row">
                            {
                                roleType !== "Super Admin" && roleType !== "Choose..." &&
                                <div className="form-group col-md-6">
                                    <label>State</label>
                                    <select onChange={handleStateChange} id="inputState" className="form-control">
                                       
                                        {
                                            allStates.map((x, i) => <option key={i}>{x}</option>)
                                        }
                                    </select>
                                    <small style={{color: 'red'}} className="form-text">{getDropdownError('state')}</small>  
                                </div>
                            }
                            {
                                (roleType === "District Admin" || roleType === "City Admin") &&
                                <div className="form-group col-md-6">
                                    <label>District</label>
                                    <select onChange={handleDistrictChange} on id="inputState" className="form-control">
                                        {districtList.length === 0 && <option>Choose...</option>}
                                        {
                                            districtList.length !== 0 && districtList.map(x => <option>{x}</option>)
                                        }
                                    </select>
                                    <small style={{color: 'red'}} className="form-text">{getDropdownError('district')}</small>  
                                </div>
                            }
                            {
                                roleType === "City Admin" &&
                                <div className="form-group col-md-6">
                                    <label>City</label>
                                    <select onChange={hanldeCityChange} id="inputState" className="form-control">
                                        {cityList.length === 0 && <option>Choose...</option>} 
                                        {
                                            cityList.length !== 0 &&
                                            cityList.map(x => <option>{x.city}</option>)
                                        }
                                    </select>
                                    <small style={{color: 'red'}} className="form-text">{getDropdownError('city')}</small>  
                                </div>
                            }
                        </div> 
                        {
                            !loading && 
                            <div className="form-group">
                                <button onClick={submit} style={{backgroundColor: '#F8C400', color: 'white'}} type="submit" className="btn mx-auto btn-block"> Add Volunteer  </button>
                            </div>
                        }
                        {
                            loading &&
                            <div style={{marginLeft: '40%'}} className="py-3">
                                <Loader type="Oval" height={50} color="#F8C400"/>
                            </div>
                        }
                    </form>
                </article>
            </div> 
            <br></br><br></br>
        </section>
        </>
    )
}

export default withRouter(AddVolunteerPage)
