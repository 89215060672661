import { useEffect, useState } from "react"
import Loader from "react-loader-spinner"
import { withRouter } from "react-router"
import Navbar from '../components/Navbar'

const DisplayUsersPage = (props) => {

    const [userList, setUserList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [input, setInput] = useState('')
    const [copy, setCopy] = useState('')

    useEffect(() => {
        if(localStorage.getItem('dhl') === null){
            props.history.push('/login')
        }
        setIsLoading(true)
        let formData = new FormData()
        formData.append('userlist', 'userlist')

        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
            if(data.success === 200){
                setIsLoading(false)
                console.log(data.de)
                setUserList(data.de)
                setCopy(data.de)
            }else{
                setIsLoading(false)
                setUserList([])
            }
        })
    }, [props.history])

    const search = () =>{
        let newList = copy.filter(x => x.id === input || x.mobileno === input)
        setUserList(newList)
    }

    const activeDeactive = (type, mobile, id) =>{
        setIsLoading(true)
        let formData = new FormData()
        formData.append(type, type)
        formData.append('mobileno', mobile)
        formData.append('id', id)

        fetch('https://darjihelpline.com/dhlapi/webapi.php', {
            method: 'POST',
            body: formData
        }).then(res => res.json()).then((data) => {
            if(data.success === 200){
                let formData1 = new FormData()
                formData1.append('userlist', 'userlist')

                fetch('https://darjihelpline.com/dhlapi/webapi.php', {
                    method: 'POST',
                    body: formData1
                }).then(res => res.json()).then((data) => {
                    if(data.success === 200){
                        setIsLoading(false)
                        console.log(data.de)
                        setUserList(data.de)
                        setCopy(data.de)
                    }else{
                        setIsLoading(false)
                        setUserList([])
                    }
                })
            }else{
                setIsLoading(false)
                setUserList([])
            }
        })
    }

    return (
        <>
         <Navbar />
        <div style={{marginTop: 100}}></div>
        <div className="mt-5">
            {
                isLoading &&
                <div className="col-12 row">
                    <div className="mx-auto card bg-white p-5" style={{marginTop: '15%'}}>
                        <Loader type="Circles" color="#EC9F2C"/>
                    </div>
                </div>
            }  
            {
                !isLoading && userList.length !== 0 &&
                <div className="row mx-3 bg-white py-3">
                    <div className="col-md-6 ">
                        <label><p style={{fontWeight: 'bold'}}>Search Help</p></label>
                        <input value={input} onChange={e => setInput(e.target.value)} placeholder="Enter Help ID or Mobile" className="form-control my-3" />
                    </div>
                    <div style={{marginTop: 37}} className="col-md-3">
                        <button onClick={search} className="btn btn-outline-info col-9 mx-auto">Find</button>
                    </div>
                    <div style={{marginTop: 37}} className="col-md-3">
                        <button onClick={() => setUserList(copy)} className="btn btn-outline-info col-9 mx-auto">Clear Search</button>
                    </div>
                </div>
            }
            {
                !isLoading && userList.length === 0 && <h1 className="text-center my-5">0 User found</h1>
            }
            {
                !isLoading &&
                userList.length !== 0 && userList.map(user => {
                    return(
                        <div className="col-md-10 mx-auto card bg-white my-3">
                            <div className="row p-3">
                                <div className="col-md-6">
                                    <h5 style={{color: user.status === '1' ? 'green' : 'red'}}>User ID: {user.id} ({user.status === '1' ? 'Active' : 'Suspended'})</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5>Name: {user.fname} {user.lname}</h5>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-6">
                                    <h5>Mobile: {user.mobileno}</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5>Email: {user.email}</h5>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-6">
                                    <h5>State: {user.state}</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5>District: {user.dist}</h5>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-6">
                                    <h5>City: {user.city}</h5>
                                </div>
                                <div className="col-md-6">
                                    <h5>Pincode: {user.pincode}</h5>
                                </div>
                            </div>
                            <div className="row p-3">
                                <div className="col-md-12">
                                    <h5>Address: {user.address}</h5>
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row p-3">
                                <div className="col-md-12">
                                    {user.status === "0" && <button onClick={() => activeDeactive('useron', user.mobileno, user.id)} style={{backgroundColor: 'green', color: 'white', borderColor: 'white', padding: 5, borderRadius: 5}}>Activate</button>}
                                    {user.status === "1" && <button onClick={() => activeDeactive('useroff', user.mobileno, user.id)} style={{backgroundColor: 'red', color: 'white', borderColor: 'white', padding: 5, borderRadius: 5}}>Deactivate</button>}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        </>
    )
}

export default withRouter(DisplayUsersPage)
